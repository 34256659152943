const SUBMITTED_CONSENT_FLAG = "submittedConsent";

export function readSubmittedConsent() {
  try {
    const value = localStorage.getItem(SUBMITTED_CONSENT_FLAG);
    return value !== null ? JSON.parse(value) : null;
  } catch (error) {
    console.error("Error reading submittedConsent from localStorage", error);
    return null;
  }
}

export function writeSubmittedConsent(value) {
  try {
    localStorage.setItem(SUBMITTED_CONSENT_FLAG, JSON.stringify(value));
  } catch (error) {
    console.error("Error writing submittedConsent to localStorage", error);
  }
}